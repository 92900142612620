<template>
  <div class="subLocationDetail">
    <app-header />
    <div class="boxes">
      <SummaryAndConclusionBoxes v-if="workingObject" :selectedSummaryAndConclusionsGuid="selectedSummaryAndConclusionsGuid" :projectId="`${$route.params.projectId}`" />
    </div>

    <editors v-if="workingObject" screen="frmSummaryConclusionDetails"></editors>
    <nv-loading :show="loading"></nv-loading>
    <app-footer :saveComponentDataFunction="save" />

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>

    <transition name="fade" appear>
      <BasePopup
        v-show="popupOpen"
        :popupOpen="popupOpen"
        :popup-title="$t('project.DeleteSummaryAndConlcusion')"
        :rightFooterButtonText="$t('project.DeleteButton')"
        :createError="createError"
        @rightButtonClicked="onClickDeleteSummaryAndConclusion"
        @closeButtonClicked="toggleSummaryAndConclusionPopup"
      >
        <p class="delete-text">{{ $t('project.DeleteSummaryAndConclusionHelp') }}</p>
      </BasePopup>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import BasePopup from '@/components/boxes/base-components/basePopup.vue'
import workingObjectUtils from '@/utils/workingObjectUtils'
import SummaryAndConclusionBoxes from '@/components/boxes/summaryAndConclusionBoxes'
import { SUMMARYANDCONCLUSION_TYPE } from '@/vuex/constants'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

var self

export default {
  name: 'summaryAndConclusion',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    SummaryAndConclusionBoxes,
    BasePopup,
    ExportProjectPopup
  },
  data() {
    return {
      loading: false,
      error: false,
      createError: false,
      popupOpen: false,
      exportPopupOpen: false

    }
  },
  computed: {
    summaryAndConclusions: {
      get() {
        return this.$store.getters.getSummaryAndConclusions
      }
    },
    selectedSummaryAndConclusionsGuid() {
      return this.$route.params.SmGUID
    },
    workingObject: {
      get() {
        return this.$store.state.workingObject
      },
      set(value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get() {
        return this.$store.state.workingObjectType
      },
      set(value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get() {
        return this.$store.state.workingObjectIndex
      },
      set(value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    }
  },
  watch: {
    // on route change, this is NOT called for fresh loads. fresh loads are handled in created()
    $route: {
      handler: function () {
        // update active summary and conclusion
        this.setActiveSummaryAndConclusion()
      },
      deep: true
    }
  },
  created() {
    self = this
    this.loading = true
    Promise.all([this.$store.dispatch('setSummaryAndConclusionsTypes', 84), this.$store.dispatch('fetchTblSummaryConclusions', { PrID: this.$route.params.projectId })]).then(() => {
      this.setActiveSummaryAndConclusion()
      this.loading = false
    })
  },
  methods: {
    setActiveSummaryAndConclusion() {
      let self = this
      this.workingObjectIndex = _.findIndex(this.summaryAndConclusions, function (summary) {
        return summary.SmGUID.toUpperCase() === self.$route.params.SmGUID.toUpperCase()
      })
      if (this.workingObjectIndex === -1) {
        this.$router.push({
          name: '404'
        })
      }
      this.summaryAndConclusions.forEach((summary) => (summary.isActive = false))

      this.workingObject = this.summaryAndConclusions[this.workingObjectIndex]
      this.workingObject.isActive = true
      this.workingObjectType = SUMMARYANDCONCLUSION_TYPE
      this.$store.state.activeTitle = this.$store.getters.getSummaryAndConclusionTypeBySmType(this.summaryAndConclusions[this.workingObjectIndex].SmType).value
    },
    save() {
      this.loading = true
      return workingObjectUtils
        .pushWorkingObjectToDataService(this.workingObject)
        .then((res) => {
          self.loading = false
          return true
        })
        .catch((err) => {
          self.loading = false
          self.handlerError(err)
        })
    },
    toggleSummaryAndConclusionPopup() {
      this.error = false
      this.createError = false
      this.popupOpen = !this.popupOpen
      if (!this.popupOpen) this.valueUser = ''
    },
    goToFirstSummaryAndConclusionIfExists() {
      if (this.$store.state.summaryAndConclusions.length > 0) {
        this.$router.push({
          name: self.$router.history.current.name,
          params: {
            projectId: self.$route.params.projectId,
            SmGUID: self.$store.state.summaryAndConclusions[0].SmGUID
          }
        })
      } else {
        this.$router.push({
          name: 'project',
          params: {
            id: self.$route.params.projectId
          }
        })
      }
    },
    onClickDeleteSummaryAndConclusion() {
      this.$store
        .dispatch('deleteSummaryAndConclusion', {
          projectId: this.$route.params.projectId,
          SmGUID: this.$route.params.SmGUID
        })
        .then(() => {
          this.$store.commit('clearWorkingObject')
          this.$store.dispatch('fetchTblSummaryConclusions', { PrID: self.$route.params.projectId }).then(() => {
            this.error = false
            this.createError = false
            this.popupOpen = false
            this.goToFirstSummaryAndConclusionIfExists()
          })
        })
    },
    deleteSummaryAndConclusion() {
      this.toggleSummaryAndConclusionPopup()
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>

<style type="less" scoped>
@media (max-width: 640px) {
  .subLocationDetail ::v-deep .edit-panel .input-group.input-left {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .subLocationDetail ::v-deep .editor-panel {
    width: calc(100vw - 410px);
  }
}
.boxes .boxesBody {
  background-color: #515151;
  color: white;
}

.delete-text {
  padding: 15px;
  color: #515151;
}
/deep/ .popupHeader {
  padding: 15px;
}
</style>
