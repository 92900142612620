<template>
  <div class="subLocationDetail">
    <app-header/>
    <boxes
      v-if="workingObject"
      :options="{
        title: 'columnTitles.MpSublocation',
        bg:'gray',
        type:'subLocationBox',
        businesskey:'subLocation',
        modal:'slModal',
        data: subLocations,
        projectId: $route.params.projectId,
        buttons: ['addButton'],
        isfieldRequest: false,
        enableBoxLink: true,
      }">
    </boxes>

    <editors v-if="workingObject" screen='frmSublocationDetails'></editors>
    <nv-loading :show="loading"></nv-loading>
    <app-footer :saveComponentDataFunction="save"/>

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>

  </div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import config from '@/configurations/config.js'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import boxes from '@/components/boxes.vue'
import { SUBLOCATION_TYPE } from '@/vuex/constants'
import workingObjectUtils from '@/utils/workingObjectUtils'
import Popup from '@/utils/popup'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

let popup = Popup[config.platform].default
var self

export default {
  name: 'subLocation',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    ExportProjectPopup
  },
  data () {
    return {
      loading: true,
      exportPopupOpen: false
    }
  },
  computed: {
    subLocations: {
      get () {
        return this.$store.getters.getSubLocations
      }
    },
    workingObject: {
      get () {
        return this.$store.state.workingObject
      },
      set (value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get () {
        return this.$store.state.workingObjectType
      },
      set (value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get () {
        return this.$store.state.workingObjectIndex
      },
      set (value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentProject: {
      get () {
        return this.$store.state.prTypeCode
      }
    }
  },
  watch: {
    // on route change, this is NOT called for fresh loads. fresh loads are handled in created()
    '$route': {
      handler: function () {
        // update active concerned contact
        this.setActiveSubLocation()
      },
      deep: true
    }
  },
  created () {
    self = this

    this.$store.dispatch('fetchTblSubLocations', { PrID: this.$route.params.projectId })
      .then(() => {
        this.setActiveSubLocation()
        this.loading = false
      })
  },
  methods: {
    setActiveSubLocation () {
      let self = this
      this.workingObjectIndex = _.findIndex(this.subLocations, function (loc) {
        return loc.SlGuid === self.$route.params.SlGUID
      })
      if (this.workingObjectIndex === -1) {
        this.$router.push({
          name: '404'
        })
      }
      this.subLocations.forEach(sl => (sl.isActive = false))

      this.workingObject = this.subLocations[this.workingObjectIndex]
      this.workingObject.isActive = true
      this.workingObjectType = SUBLOCATION_TYPE

      this.$store.state.activeTitle = this.subLocations[this.workingObjectIndex].SlName
    },
    save () {
      this.loading = true
      return workingObjectUtils.pushWorkingObjectToDataService(this.workingObject)
        .then(res => {
          self.loading = false
          // update subLocations contacts with response from server
          self.subLocations = res

          return true
        })
        .catch(err => {
          self.loading = false
          self.handlerError(err)
        })
    },
    deleteSubLocation () {
      let modalDelete
      modalDelete = $('#modalDeleteSubLocation')
      modalDelete.modal('toggle')
    },
    handlerError (err) {
      popup.popup(err.body)
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>

<style>
    @media (max-width: 640px) {
      .subLocationDetail .edit-panel .input-group.input-left {
        width: 100%;
      }
    }

    @media (min-width: 768px) {
      .subLocationDetail .editor-panel {
        width: calc(100vw - 410px);
      }
    }
</style>
